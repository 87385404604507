export const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  ipApiUrl: process.env.NEXT_PUBLIC_IP_API_URL,
  ipApiKey: process.env.NEXT_PUBLIC_IP_API_KEY,
  membershipUrl: process.env.NEXT_PUBLIC_MEMBERSHIP_URL,
  basePath: process.env.NEXT_PUBLIC_BASE_PATH,
  email: process.env.NEXT_PUBLIC_APP_EMAIL,
  phone: process.env.NEXT_PUBLIC_APP_PHONE,
  gtmId: process.env.NEXT_PUBLIC_APP_GTM,
  calendlyUrl: process.env.NEXT_PUBLIC_CALENDLY_URL,
  devtools: process.env.NEXT_PUBLIC_DEVTOOLS,
};
